<template>
  <div>
    <heads :states="states" :more="showMore" :msg="title_msg"></heads>
    <div class="chat-card">
      <div v-if="historyList&&historyList.length>0" class="chat-content chat-content2">
        <div v-for="(item,index) in historyList" :key="index" class="content-main">
          <div class="picture">
            <img :src="item.type == 1 ? 'https://newoss.zhulong.com/forum/202206/02/46/103546ckyxy5lcwwkes8du.png':avator" alt="" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
            <p v-if="item.type == 1">小鱼儿</p>
            <p v-else>用户名</p>
          </div>
          <div class="content-body" :style="item.type==2?'background:#fbf0f0':''">
            <div>
              <div v-if="item.type==1" class="message incoming">
                <div v-html="item.content"></div>
              </div>
              <div v-if="item.type==2" class="message outgoing">
                <p>{{ item.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-top">
        <div class="picture">
          <img src="https://newoss.zhulong.com/forum/202206/02/46/103546ckyxy5lcwwkes8du.png" alt="">
          <p>小鱼儿</p>
        </div>
        <div class="chat-body">
          <div class="name">你好，我是小鱼儿~</div>
          <div class="dec">作为筑龙<span>AI建筑知识问答助手</span>，</div>
          <div class="dec">我能为你提供建筑知识相关问题的解决方案，和设计规范中的相关回答。</div>
          <div class="dec dec2">你可以试着问我：</div>

          <div class="questionlist">
            <div v-for="(item,index) in questionlist" :key="index" @click="!btn_disabled&&send(item.content)">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <div class="chat-content">
        <div v-for="(item,index) in list" :key="index" class="content-main">
          <div class="picture">
            <img :src="item.type == 1 ? 'https://newoss.zhulong.com/forum/202206/02/46/103546ckyxy5lcwwkes8du.png':avator" alt="" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
            <p v-if="item.type == 1">小鱼儿</p>
            <p v-else>我</p>
          </div>
          <div class="content-body" :style="item.type==2?'background:#fbf0f0':''">
            <div>
              <div v-if="item.type==1" class="message incoming">
                <div v-if="index==list.length-1&&loading">
                  <section class="dots-container">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </section>
                </div>
                <div v-else>
                  <div v-html="item.content"></div>
                  <div v-if="(index!=list.length-1||!btn_disabled)&&item.is_stop" class="stop_text"><span style="rgba(149, 149, 149, 1);margin: 5px 0 0px;display: inline-block;">已停止生成</span></div>
                  <div v-if="index!=list.length-1||!btn_disabled" class="copy">
                    <div v-if="item.is_stop" class="stop_text">
                      <span style="color: #ee2e2e;margin: 8px 0 0px;display: inline-block;" @click="send(list[index - 1].content)">重新生成</span>
                    </div>
                    <div v-else class="btn">
                      <img src="https://newoss.zhulong.com/forum/202404/16/17/1713258049228690.png" alt="" @click="copy(item.initial_content,index)">
                      <span class="sline"></span>
                      <img id="copy_text" :data-clipboard-text="item.link_url" class="img2" src="https://newoss.zhulong.com/forum/202404/22/15/1713772767987141.png" alt="" @click="share(item,index)">
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.type==2" class="message outgoing">
                <p>{{ item.content }}</p>
              </div>
            </div>
            <div v-if="copy_share_index==index" class="tip">
              <span>{{ tip_text }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-bottom">
        <div class="question-body">
          <el-input
            v-model="message"
            :rows="3"
            type="text"
            placeholder="请输入您的问题"
          >
          </el-input>
        </div>
        <div v-if="!btn_disabled" class="send" @click="send()">
          <img src="https://newoss.zhulong.com/forum/202404/22/14/1713769076258749.png" alt="">
        </div>
        <div v-else @click="stop">
          <img src="https://newoss.zhulong.com/forum/202404/22/16/1713774304531809.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cookies from 'js-cookie'
import MarkdownIt from 'markdown-it'
import hljs from 'highlight.js'
import Clipboard from 'clipboard'
import heads from '@/compontens/heads.vue'
import { Toast } from 'vant'
import { createShare, gethistorymsg } from '@/api/chatGPT'
import { getMiniUrlLink } from '@/api/login'
export default {
  components: {
    heads
  },
  data() {
    return {
      states: 1,
      showMore: true,
      title_msg: '规范查询',
      hisrorymsg: 0, // 首次提问需传0，之后传1
      eventSource: null,
      is_stop: false, // 是否是自己主动停止生成
      questionlist: [
        {
          'id': 1,
          'content': '建筑行业有哪些高含金量的证书可以考？'
        },
        {
          'id': 2,
          'content': '楼梯扶手的高度应该是多少？'
        },
        {
          'id': 3,
          'content': '在民用建筑设计统一标准中，是如何规定建筑分类的？'
        }
      ],
      loading: false, // 加载动画
      btn_disabled: false, // 控制按钮是否可以点击
      message: '',
      alldata: '',
      historyList: [],
      list: [],
      tip_text: '', // 1.复制提示文本；2.分享提示文本
      copy_share_index: '',
      shareId: '',
      copyLink: '',
      wx_url: '' // 跳转微信小程序链接
    }
  },
  created() {
    this.getAvator(cookies.get('uid'))
  },
  mounted() {
    // 获取历史记录
    this.gethistorymsg()
  },
  methods: {
    gethistorymsg() {
      gethistorymsg().then(res => {
        if (res.errNo == 0) {
          var list = res.result || []
          console.log(list, 'list')
          list.forEach(element => {
            this.historyList.push(
              {
                type: 2,
                content: element.message
              }, {
                type: 1,
                content: this.renderMarkdown(element.sysmessage)
              }
            )
          })
        }
      }).then(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0
        })
      })
    },
    // 停止
    stop() {
      this.btn_disabled = false
      // this.is_stop = true
      this.list[this.list.length - 1].is_stop = true
      this.loading = false
      this.eventSource.close()
    },
    // 发送
    send(val) {
      // this.is_stop = false
      if (val) {
        this.message = val
      }
      console.log(this.message, 'this.message')
      if (this.message) {
        this.list.push({
          'content': this.message,
          'type': 2
        })
        var message = this.message
        this.message = ''
        this.btn_disabled = true
        setTimeout(() => {
          var obj = {
            'content': '', // md转之后的内容
            'initial_content': '', // 接口返回的初始化内容
            'link_url': '',
            'type': 1
          }
          this.list.push(obj)
          this.loading = true
          this.alldata = []
          this.chat2(message)
        }, 500)
      } else {
        this.$message({
          message: '请输入内容',
          type: 'warning'
        })
      }
    },
    // 接口请求
    chat2(message) {
      console.log(message, '--------message')
      this.scorellToBottom()
      this.eventSource = new EventSource(`https://m.zhulong.com/ucenter/prod-api/api/aiv2/chat?message=${encodeURIComponent(message)}&hisrorymsg=0&search=1`)
      this.eventSource.onmessage = (event) => {
        this.loading = false
        // 当接收到消息时，解析数据并更新messages数组
        const data = JSON.parse(event.data)
        if (data.type === 'load_complete') {
          // 数据加载完成的操作
          console.log('数据加载完成')
          this.btn_disabled = false
        } else {
          // console.error(data);
          // this.messages.push(event.data);
          var data_str = data.data
          this.alldata += data_str
          // var new_data_str = data_str.replace(/\n/g, '<br>')
          this.list[this.list.length - 1].initial_content = this.alldata
          this.list[this.list.length - 1].content = this.renderMarkdown(this.alldata)
          this.scorellToBottom()
        }
      }
      this.eventSource.onerror = (error) => {
        console.error('SSE connection failed:', error)
        this.loading = false
        this.btn_disabled = false
        this.eventSource.close()
        // 处理错误，例如尝试重新连接或显示错误消息
        // this.messages.push({ id: Date.now(), text: '连接失败，尝试重新输入问题。' });

        // 生成结束后生成跳转小程序短连接
        const info = {
          ask: this.list[this.list.length - 1].initial_content, // 当前问题答案
          answer: this.list[this.list.length - 2].content // 相对应的提问
        }
        createShare(info).then(res => {
          console.log(res, 'res')
          if (res.errNo == 0) {
            this.shareId = res.result.shareId // 获取分享id
          }
        }).then(() => {
          // const str = window.location.origin + 'ucenter/chatTransferPage?shareId=' + this.shareId
          const str = 'https://m.zhulong.com/ucenter/chatTransferPage?shareId=' + this.shareId
          var share = encodeURIComponent(str)
          const info = {
            path: '/pages/webIndex/webIndex',
            query: `share=${share}`,
            // env_version: 'release' // 正式环境
            env_version: 'trial' // 体验版环境
          }
          // 生成短链接口
          getMiniUrlLink(info).then(res => {
            console.log(res, 'res')
            if (res.result) {
              this.wx_url = res.result || ''
            } else {
              this.wx_url = ''
            }
          }).then(() => {
            // 存储传递过来的数据
            // this.wx_url 这个是短连接
            var str2 = this.wx_url
            // var copytext = '快来看看！我和筑龙ai建筑知识问答助手的对话噢~ 点击链接筑龙ai建筑知识问答助手，能为你提供建筑知识相关问题的解决方案，和设计规范中的相关回答。'
            // 给当前问题回复的条目加 文本 => 可点击文本打开程序
            this.list[this.list.length - 1].link_url = '快来看看！我和筑龙ai建筑知识问答助手的对话噢~ 点击链接' + str2 + '筑龙ai建筑知识问答助手，能为你提供建筑知识相关问题的解决方案，和设计规范中的相关回答。'
          })
        })
      }
    },
    // markdown渲染，highlight.js高亮
    renderMarkdown(text) {
      const md = new MarkdownIt({
        highlight: function(str, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return `<pre class="hljs"><code>${hljs.highlight(str, { language: lang }).value}</code></pre>`
            } catch (__) {}
          }

          return `<pre class="hljs"><code>${md.utils.escapeHtml(str)}</code></pre>`
        }
      })
      return md.render(text)
    },
    // 将聊天窗口滚动到最底部
    scorellToBottom() {
      // var element = document.getElementsByClassName('chat-card')[0] // 获取页面中所有类名为 'chat-content' 的元素的第一个元素
      // console.log(element.scrollHeight, 'element.scrollHeight')
      // window.scrollTop = element.scrollHeight // 将该元素的滚动位置设置为其内容的高度，实现滚动到最底部
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
      // var chatBody = document.getElementsByClassName('chat-body')[0];
      // if (chatBody.scrollTop + chatBody.clientHeight >= chatBody.scrollHeight) {
      //   // 当滚动条位于底部时，执行滚动到底部的操作
      //   chatBody.scrollTop = chatBody.scrollHeight;
      // }
    },

    // 复制
    copy(text, index) {
      // var copytext = text.replace(/<[^>]*>/g, '')
      var copytext = text.replace(/[#*>+_`-]/g, '').replace(/!\[.*\]\(.*\)/g, '').replace(/\[.*\]\(.*\)/g, '').replace(/\*\*\*/g, '').replace(/\*\*[^*]\*\*/g, '').replace(/\*[^\\*]\*/g, '').replace(/__[^_]__/g, '').replace(/_[^_]_/g, '')
      this.textCopy(copytext, index)
    },
    // 分享
    share(item, index) {
      // this.createShare(item.initial_content, index)
      this.linkCopy('', index)
    },
    // 分享接口
    // createShare(ask, index) {
    //   const info = {
    //     ask: ask,
    //     answer: this.list[index - 1].content
    //   }
    //   createShare(info).then(res => {
    //     console.log(res, 'res')
    //     if (res.errNo == 0) {
    //       this.shareId = res.result.shareId
    //     }
    //   }).then(() => {
    //     // const str = window.location.origin + 'ucenter/chatTransferPage?shareId=' + this.shareId
    //     const str = 'https://m.zhulong.com/ucenter/chatTransferPage?shareId=' + this.shareId
    //     var share = encodeURIComponent(str)
    //     const info = {
    //       path: '/pages/webIndex/webIndex',
    //       query: `share=${share}`,
    //       // env_version: 'release' // 正式环境
    //       env_version: 'trial' // 体验版环境
    //     }
    //     getMiniUrlLink(info).then(res => {
    //       console.log(res, 'res')
    //       if (res.result) {
    //         this.wx_url = res.result || ''
    //       } else {
    //         this.wx_url = ''
    //       }
    //     }).then(() => {
    //       // 存储传递过来的数据
    //       var str2 = this.wx_url
    //       // var copytext = '快来看看！我和筑龙ai建筑知识问答助手的对话噢~ 点击链接筑龙ai建筑知识问答助手，能为你提供建筑知识相关问题的解决方案，和设计规范中的相关回答。'
    //       this.copyLink = '快来看看！我和筑龙ai建筑知识问答助手的对话噢~ 点击链接' + str2 + '筑龙ai建筑知识问答助手，能为你提供建筑知识相关问题的解决方案，和设计规范中的相关回答。'
    //     }).then(() => {
    //       this.linkCopy(this.copyLink, index)
    //     })
    //   })
    // },
    textCopy(text, index) {
      const OrderNumber = text
      // 创建一个input 元素
      // createElement() 方法通过指定名称创建一个元素
      const newInput = document.createElement('textarea')
      // 讲存储的数据赋值给input的value值
      newInput.value = OrderNumber
      // appendChild() 方法向节点添加最后一个子节点。
      document.body.appendChild(newInput)
      // 选中input元素中的文本
      // select() 方法用于选择该元素中的文本。
      newInput.select()
      // 执行浏览器复制命令
      //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
      document.execCommand('Copy')
      // 清空输入框
      newInput.remove()
      // this.$message.success('复制成功')
      this.copy_share_index = index
      this.tip_text = '文字已复制'
      setTimeout(() => {
        this.tip_text = ''
        this.copy_share_index = ''
      }, 2000)
    },
    linkCopy(text, index) {
      var clipboard = new Clipboard('#copy_text')

      clipboard.on('success', (e) => {
        this.copy_share_index = index
        this.tip_text = '链接已复制'
        setTimeout(() => {
          this.tip_text = ''
          this.copy_share_index = ''
        }, 2000)
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        Toast('该浏览器不支持复制')
        // 释放内存
        clipboard.destroy()
      })
    },
    // 生成头像
    getAvator(uid) {
      const domain = 'https://newoss.zhulong.com/tfs/'
      let num = uid
      const n = 9
      let len = num.toString().length
      while (len < n) {
        num = '0' + num
        len++
      }
      uid = num
      const dir1 = uid.substr(0, 3)
      const dir2 = uid.substr(3, 2)
      const dir3 = uid.substr(5, 2)
      const time = parseInt(new Date().getTime() / 1000) + ''
      const ava = domain + dir1 + '/' + dir2 + '/' + dir3 + '/' + uid.substr(-2) + '_avatar_big.jpg?t=' + time
      this.avator = ava
    }
  }
}
</script>
<style scoped lang="scss">
.chat-card{
  width: 670px;
  margin: 21px auto 0;
  display: flex;
  flex-flow: column;
  .picture{
    display: flex;
    align-items: center;
    img{
      width: 46px;
      border-radius: 10px;
      margin-right: 10px;
    }
    p{
      font-size: 32px;
    }
  }
  .chat-top{
    margin-bottom: 17px;
    .chat-body{
      width: 560px;
      margin: 10px 0 0 60px;
      border-radius: 10px;
      background: rgba(247, 247, 247, 1);
      padding: 43px 21px 10px;
      .name{
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 52px;
        color: rgba(23, 23, 23, 1);
        margin: 0 0 10px;
      }
      .dec{
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 43px;
        color: rgba(68, 68, 68, 1);
        span{
          color: rgba(215, 22, 22, 1);
        }
      }
      .dec2{
        margin: 40px 0 22px 0;
      }
      .questionlist{
        width: 560px;
        margin: 0 auto;
        >div{
          width: calc(100% - 40px);
          border-radius: 14px;
          background: rgba(251, 240, 240, 1);
          padding: 20px 20px;
          font-size: 28px;
          font-weight: 500;
          line-height: 41px;
          color: rgba(0, 0, 0, 1);
          margin-bottom: 10px;
        }
      }
    }
  }
  .chat-content{
    padding-bottom: 310px;
    .content-main{
      margin-bottom: 17px;
    }
    .content-body{
      width: 560px;
      margin: 10px 0 0 60px;
      border-radius: 10px;
      background: rgba(247, 247, 247, 1);
      padding: 20px 21px 20px;
      position: relative;
      .message {
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 43px;
        color: rgba(68, 68, 68, 1);
      }
      .incoming {
        .copy{
          width: 560px;
          margin: 10px auto 0;
          border-top: 2px solid rgba(218, 218, 218, 1);
          text-align: right;
          margin-right: 20px;
          .btn{
            margin-top: 20px;
            display: inline-block;
            cursor: pointer;
            img{
              width: 32px;
              vertical-align: text-top;
            }
            .img2{
              width: 40px;
              margin-top: -4px;
            }
            .sline{
              margin: 0 20px;
              color: #999;
              display: inline-block;
              height: 30px;
              border-right: 1px solid rgba(205, 205, 205, 1);
            }
          }
        }
      }
      .outgoing {

      }
      .tip{
        position: absolute;
        right: 0;
        bottom: 0;
        margin-bottom: -32px;
        color: #ee2e2e;
      }
    }
  }
  .chat-content2{
    padding-bottom: 30px;
  }
  .chat-bottom{
    position: fixed;
    bottom: 0;
    padding: 20px 0 60px;
    display: flex;
    align-items: center;
    background: #fff;
    .question-body{
      height: 70px;
      .el-input{
        width: 610px;
        margin-right: 20px;
        /deep/.el-input__inner{
          height: 70px;
          border-radius: 100px;
          border: 2px solid rgba(224, 224, 224, 1);
        }
      }
    }
    .send{
      img{
        width: 44px;
        display: block;
      }
    }
  }
}
.stop_text{
  text-align: left;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 35px;
}

// 加载动画
.dots-container {
  display: flex;
  align-items: center;
  height: 21px;
  width: 100%;
}

.dot {
  height: 5px;
  width: 5px;
  margin-right: 5px;
  border-radius: 5px;
  background-color: #dfdfdf;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    // transform: scale(0.8);
    background-color: #dfdfdf;
    box-shadow: 0 0 0 0 rgba(233, 234, 235, 0.7);
  }

  50% {
    // transform: scale(1.2);
    background-color: #333;
    box-shadow: 0 0 0 2px rgba(178, 212, 252, 0);
  }

  100% {
    // transform: scale(0.8);
    background-color: #dfdfdf;
    box-shadow: 0 0 0 0 rgba(233, 234, 235, 0.7);
  }
}
</style>
